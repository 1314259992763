body {
  margin: 0;
  width: 100%;
  min-height: 100%;
  overflow-x: hidden;
  background-color: var(--lightest-teal) !important;
  font-family: 'Nunito Sans Regular', -apple-system, BlinkMacSystemFont, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: 'Nunito Sans Regular';
  src: local('Nunito Sans'), url(/static/media/NunitoSans-Regular.4c8f4470.ttf) format('truetype');
}

@font-face {
  font-family: 'Nunito Sans Bold';
  src: local('Nunito Sans'), url(/static/media/NunitoSans-Bold.08e53a51.ttf) format('truetype');
}
:root {
  --navy: #151c3a;
  --dark-teal: #1b8f9f;
  --light-teal: #aeeef0;
  --very-light-teal: #d7f7f8;
  --lightest-teal: #f0ffff;
  --grey: #404040;
  --light-grey: #808080;
  --very-light-grey: #d0d0d0;
  --translucent: #ffffff80;
  --font-nunito-sans-regular: 'Nunito Sans Regular', Arial, Helvetica, sans-serif;
  --font-nunito-sans-bold: 'Nunito Sans Bold', Arial, Helvetica, sans-serif;
}

html {
  scroll-behavior: smooth;
}

.sectionHeader {
  text-align: center;
  font-family: 'Nunito Sans Bold', Arial, Helvetica, sans-serif;
  font-family: var(--font-nunito-sans-bold);
  font-size: 30px;
  letter-spacing: 5px;
  color: #151c3a;
  color: var(--navy);
  text-transform: uppercase;
  padding-top: 80px;
  padding-bottom: 10px;
}

.headerLine {
  border-top: 3px solid #1b8f9f;
  border-top: 3px solid var(--dark-teal);
  width: 70px;
  margin: auto;
}

.highlight {
  color: #1b8f9f;
  color: var(--dark-teal);
  font-family: 'Nunito Sans Bold', Arial, Helvetica, sans-serif;
  font-family: var(--font-nunito-sans-bold);
}

.bold {
  font-family: 'Nunito Sans Bold', Arial, Helvetica, sans-serif;
  font-family: var(--font-nunito-sans-bold);
}
.defaultNav {
  box-shadow: none !important;
}

.activeNav {
  transform: translateY(0px);
}

.hiddenNav {
  transform: translateY(calc(100px * -1));
}

.navbar {
  position: fixed !important;
  z-index: 1000;
  top: 0;
  width: 100%;
  text-align: right;
  background: var(--lightest-teal);
  box-shadow: 0px 0px 8px grey; 
  font-family: var(--font-nunito-sans-regular);
  text-transform: uppercase;
  transition: transform 200ms ease-in;
}

.navbarMenu {
  color: var(--navy) !important;
  border-color: transparent !important;
}
.navbarMenu:hover {
  color: var(--dark-teal) !important;
}
.navbarMenu:focus {
  outline: none !important;
}

.navbarLink {
  color: var(--navy) !important;
}
.navbarLink:hover {
  color: var(--dark-teal) !important;
}
.homepage {
  position: relative;
  min-height: 100vh;
  overflow: hidden;
}

.box {
  margin: 70px;
  height: 75vh;
  text-align: center;
}

.name {
  text-align: center;
  padding-top: 18vh;
  font-size: 100px; 
  font-family: var(--font-nunito-sans-bold);
  color: var(--navy);
}

.line {
  border-top: 3px solid var(--dark-teal);
  width: 100px;
  text-align: center;
  display: inline-block;
}

.blurb {
  text-align: center;
  font-family: var(--font-nunito-sans-regular);
  font-size: 30px;
  color: var(--grey);
  text-transform: lowercase;
  margin-top: 25px;
}

.links {
  text-align: center;
  padding-top: 5vh;
}

.icon {
  background: transparent;
  padding: 1px;
  text-align: center;
  display: inline-block;
  padding-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 50px;
  margin-bottom: 150px;

  position: relative;
  text-decoration: none;
}
.icon:hover {
  opacity: 1.0;
  transition: all 0.2s ease-out;
}
.icon:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: var(--dark-teal);
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out 0s;
}
.icon:hover:before {
  visibility: visible;
  transform: scaleX(1);
}

.homepageIcon {
  fill: var(--navy);
  width: 30px;
  height: 30px;
}

.j {
  font-family: Arial, Helvetica, sans-serif;
}

@media screen and (max-width: 600px) {
  .name {
    font-size: 60px;
  }

  .blurb {
    font-size: 20px;
  }

  .box {
    margin: 50px;
  }

  .homepageIcon {
    width: 25px;
    height: 25px;
  }
}
.aboutPage {
  position: relative;
  overflow: hidden;
}

.aboutSection {
  padding: 50px 100px 100px !important;
}

.profile {
  text-align: center;
  padding-bottom: 30px;
}

.profilePic {
  background-color: var(--light-teal);
  width: 300px; 
  border-radius: 50% !important; 
}

.aboutName {
  font-family: var(--font-nunito-sans-bold);
  font-size: 25px;
  color: var(--navy);
  text-align: center;
}

.aboutInfo {
  font-family: var(--font-nunito-sans-regular);
  font-size: 17px;
  color: var(--grey);
  text-align: left;
}

@media screen and (max-width: 1000px) {
  .aboutInfo {
    text-align: center;
  }

  .aboutSection {
    padding: 50px 30px 100px !important;
  }
}
.experiencePage {
  position: relative;
}

.jobTitle {
  font-family: var(--font-nunito-sans-bold) !important;
  font-size: 19px;
  color: var(--navy);
  padding-top: 30px;
}

.date {
  font-family: var(--font-nunito-sans-regular) !important;
  font-size: 16px;
  color: var(--light-grey);
}

.jobDescription {
  font-family: var(--font-nunito-sans-regular) !important;
  font-size: 16px;
  color: var(--grey);
  -webkit-padding-start: 25px;
          padding-inline-start: 25px;
  padding-top: 10px;
  list-style-image: url(/static/media/Circle.5cd180df.svg);
}

.jobDescriptionBullets {
  padding-left: 10px;
}

.experienceContainer {
  padding: 30px 200px 100px !important;
}

.experienceTabContainer {
  justify-content: center !important;
  font-family: var(--font-nunito-sans-regular) !important;
  border: none !important;
}

.experienceTab {
  color: var(--grey) !important;
  font-family: var(--font-nunito-sans-bold) !important;
  border: none !important;
  border-top-left-radius: 0rem !important;
  border-top-right-radius: 0rem !important;
  border-bottom: 2px solid var(--very-light-grey) !important;
  flex-basis: 0 !important;
  flex-grow: 1 !important;
}
.experienceTab:hover {
  color: var(--dark-teal) !important;
  background-color: transparent !important;
}
.experienceTab.active {
  color: var(--dark-teal) !important;
  background-color: transparent !important;
  border-bottom: 2px solid var(--dark-teal) !important;
}

@media screen and (max-width: 1000px) {
  .experienceContainer {
    padding: 30px 30px 100px !important;
  }
}
.projectsPage {
  position: relative;
}

.projectGridContainer {
  padding: 30px 200px 150px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 2rem;
}

.projectCard {
  font-family: var(--font-nunito-sans-regular) !important;
  background-color: var(--very-light-teal) !important;
  border: none !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .2);
  transform: scale(1);
}
.projectCard:hover {
  transform: scale(1.05);
  transition: all 100ms ease-in-out 0s;
}

.projectTitle {
  color: var(--navy);
  font-family: var(--font-nunito-sans-bold) !important;
}

.projectDescription {
  color: var(--grey);
}

.projectGitHub {
  color: var(--grey);
  width: 20px;
  height: 20px;
}
.projectGitHub:hover {
  color: var(--dark-teal);
}

.projectTools {
  background-color: var(--very-light-teal) !important;
  border: none !important;
} 

@media screen and (max-width: 600px) {
  .projectGridContainer {
    padding: 30px 30px 100px;
  }
}
.contactContainer {
  text-align: center;
  padding: 10px 300px 100px !important;
}

.contactBlurb {
  text-align: center;
  font-family: var(--font-nunito-sans-regular);
  font-size: 20px;
  color: var(--grey);
  margin: 20px;
}

.contactButton {
  border-radius: 60px !important;
  border: none !important;
  box-shadow: 0 3px 8px var(--navy);
  background-color: var(--dark-teal) !important;
  font-family: var(--font-nunito-sans-bold);
  color: white !important;
  padding: 15px 25px 15px !important;
}
.contactButton:hover {
  box-shadow: inset 0 0 10px var(--navy);
}

.contactPage {
  position: relative;
  background-color: var(--very-light-teal);
  padding-bottom: 20px;
}

@media screen and (max-width: 600px) {
  .contactBlurb {
    font-size: 18px;
  }
}

@media screen and (max-width: 1000px) {
  .contactContainer {
    padding: 10px 20px 100px !important;  
  }
}
