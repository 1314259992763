.defaultNav {
  box-shadow: none !important;
}

.activeNav {
  transform: translateY(0px);
}

.hiddenNav {
  transform: translateY(calc(100px * -1));
}

.navbar {
  position: fixed !important;
  z-index: 1000;
  top: 0;
  width: 100%;
  text-align: right;
  background: var(--lightest-teal);
  box-shadow: 0px 0px 8px grey; 
  font-family: var(--font-nunito-sans-regular);
  text-transform: uppercase;
  transition: transform 200ms ease-in;
}

.navbarMenu {
  color: var(--navy) !important;
  border-color: transparent !important;
}
.navbarMenu:hover {
  color: var(--dark-teal) !important;
}
.navbarMenu:focus {
  outline: none !important;
}

.navbarLink {
  color: var(--navy) !important;
}
.navbarLink:hover {
  color: var(--dark-teal) !important;
}