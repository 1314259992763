body {
  margin: 0;
  width: 100%;
  min-height: 100%;
  overflow-x: hidden;
  background-color: var(--lightest-teal) !important;
  font-family: 'Nunito Sans Regular', -apple-system, BlinkMacSystemFont, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: 'Nunito Sans Regular';
  src: local('Nunito Sans'), url(./fonts/NunitoSans/NunitoSans-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Nunito Sans Bold';
  src: local('Nunito Sans'), url(./fonts/NunitoSans/NunitoSans-Bold.ttf) format('truetype');
}